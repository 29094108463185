import React from 'react';
import { Layout,Image, } from 'antd';
import './index.less';


const Footer  = ()=>{
    
    return (
        <Layout.Footer className='app-footer'>
            <div className='app-footer-box'>
            <div className=''>
                
            </div>
            <p>
            Copyright @ 2024 深圳市常量科技有限公司 Allrights reserved. 
            备案号:
                <a className='bottom-authority-href' href="http://beian.miit.gov.cn/" target="_blank"
                rel="noreferrer">粤ICP备2023084633号-1
                </a>
            </p>
            </div>
            
        </Layout.Footer>
    )
}

export default Footer;