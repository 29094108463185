import React, {useEffect, useState } from 'react';
import { Layout,Image, Row} from 'antd';
import './index.less'

import routes from '../../routes';
import { NavLink } from 'react-router-dom';
const logoImg = 'https://cdn.szconst.com/static/clWebsite/logo.png'
const Header = ()=>{
   
    return (
        <Layout.Header  className='app-header'>
              <div className='app-header-content'>
                <Image src={logoImg} className='app-header-content-logo' preview={false}/>
                <div className='app-header-nav'>
                    {
                        routes.filter(item=>item.path !== '/' && !item.hide).map((item,index)=>(
                            <NavLink
                                className='app-link' 
                                activeClassName='active-app-link' 
                                key={index} 
                                to={item.children ? `${item.path}?type=${item.children[0].key}` : item.path} 
                                defaultChecked={item.path === '/home' }
                            >
                                {
                                    item.children ? (
                                        <div>
                                            {item.title}
                                        </div>
                                    ):(
                                        <div>
                                            <span>{item.title}</span>
                                            <span>{item.label}</span>
                                        </div>
                                    )
                                }

                            </NavLink>
                        ))
                    }
                </div>
              </div>
        </Layout.Header>
    )
}

export default Header;