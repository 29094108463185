import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route,HashRouter } from "react-router-dom";
import { Provider } from 'jotai';
import zhCN from 'antd/es/locale/zh_CN';
import {ConfigProvider} from 'antd';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter basename=''>
    <Route 
      path='/' 
      render={routeProps => {
        return (
          <ConfigProvider locale={zhCN}>
            <Provider>
              <App />
            </Provider>
          </ConfigProvider>
        )
      }}
    />
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
